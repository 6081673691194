<template>
  <div class="legal-recharge-detail">
    <van-config-provider :theme-vars="themeVars">
      <van-nav-bar :title="receiveAddress.name" left-arrow @click-left="goBack" :border="false"></van-nav-bar>
    </van-config-provider>
    <div class="detail-wrap">
      <div class="detail-content">
        <van-config-provider :theme-vars="cellThemeVars">
          <van-cell center :title="$t('field.account')" :label="sellerAccount.sellerAccount"></van-cell>
          <van-cell center :title="$t('field.address')" :label="sellerAccount.qrCode"></van-cell>
        </van-config-provider>
        <vue-qrcode :value="sellerAccount.qrCode" :options="{ width: 220 }" />
        <van-button
          v-if="sellerAccount.reviewed === 1"
          size="large"
          type="primary"
          block
          @click="handleToggleSellerAccount"
        >{{sellerAccount.status == 1 ? $t('button.open') : $t('button.close') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance } from 'vue';
import { Toast } from 'vant';
import VueQrcode from '@chenfengyuan/vue-qrcode';
import i18n from '@/assets/lang/index';
import { enableSellerAccount, disableSellerAccount, delSellerAccount } from "@/api";

export default {
  components: {
    [VueQrcode.name]: VueQrcode,
  },
  
  setup() {
    const internalInstance = getCurrentInstance();
    const globalProperties = internalInstance ? internalInstance.appContext.config.globalProperties : {};
    
    const themeVars = {
      navBarBackgroundColor: globalProperties.$primary,
      navBarTitleTextColor: '#fff',
      navBarIconColor: '#fff',
    };
    const cellThemeVars = {
      cellBorderColor: 'transparent',
    };
      
    return { themeVars, cellThemeVars };
  },
  data() {
    return {
      receiveAddress: JSON.parse(localStorage.getItem('receiveAddress')),
      sellerAccount: JSON.parse(localStorage.getItem('sellerAccount')),
    };
  },
  
  mounted() {
  },

  methods: {
    handleToggleSellerAccount() {
      if (this.sellerAccount.status == 1) {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: i18n.global.t('alert.open'),
        })
        .then(() => {// on confirm
          enableSellerAccount({
            id: this.sellerAccount.id,
          }).then(res => {
            this.goBack();
          });
        })
        .catch(() => {});
      } else {
        this.$myDialog.confirm({
          title: i18n.global.t('alert.warnning'),
          message: i18n.global.t('alert.close'),
        })
        .then(() => {// on confirm
          disableSellerAccount({
            id: this.sellerAccount.id,
          }).then(res => {
            this.goBack();
          });
        })
        .catch(() => {});
      }
    },
    goBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="less">
.legal-recharge-detail {
  .detail-wrap {
    margin: 16px 22px 10px;
    border-radius: 6px;
    background: #fff;
    .detail-content {
      font-size: 14px;
      color: #6F6F93;
      font-weight: 400;
    }
    canvas {
      display: block;
      margin: 0 auto;
    }
    .van-button {
      border-radius: 0 0 6px 6px;
    }
  }
}
</style>
